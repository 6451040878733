import React, { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import BackdropIcon from "../BackdropIcon";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const StudyLink = (props) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleButton = () => {
    sendMail();
  };
  const handleClose = () => {
    setOpenError(false);
  };
  const [link, setLink] = useState("");
  const [message, setMessage] = useState("");
  const [openError, setOpenError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [sendingMail, setSendingMail] = useState(false);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={() => openInNewTab(link)}>
        Open
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const sendMail = async () => {
    const session_url = `${process.env.REACT_APP_BACKEND_PHP_URL}/studylink.php`;
    setSendingMail(true);

    var config = {
      method: "post",
      url: session_url,
      data: {
        studyid: props.id,
        sid: props.token,
      },
    };
    const res = await axios(config);
    console.log(res.data);
    setLink(res.data);
    navigator.clipboard.writeText(res.data);
    if ((res.data = 1)) {
      setErrorText("Your mail has been sent successfuly");
      setSendingMail(false);
    } else {
      setErrorText("Something went wrong");
      setSendingMail(false);
    }

    setOpenError(true);
  };
  if (sendingMail) {
    return <BackdropIcon content={<SendIcon />} />;
  }

  return (
    <>
      <Button
        sx={{
          background: "#80848c",
          fontSize: "11px",
          ":hover": {
            bgcolor: "#0290A2",
            color: "black",
          },
        }}
        onClick={handleButton}
        variant="contained"
        endIcon={<ContentCopyIcon />}
      >
        {props.text}
      </Button>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={openError}
        autoHideDuration={6000}
        message="Public link copied to clipboard"
        onClose={handleClose}
        action={action}
      />
    </>
  );
};
export default StudyLink;
