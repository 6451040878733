import React, { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import {
  Divider,
  Box,
  Stack,
  Card,
  CardMedia,
  CardActionArea,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles({
  root: {
    width: 300,
    maxHeight: 360,
    position: "relative",
    margin: 6,
    maxWidth: "10em",
    // margin: "16 auto",
  },
  icon: {
    position: "absolute",
    zIndex: 3,
    bottom: -5,
    right: -5,
    borderRadius: 7,
    backgroundColor: "rgba(255, 0, 0, 0.6)",
  },
  thumbCover: {
    zIndex: 1,
  },
  thumbDesc: {
    zIndex: 3,
    bottom: 16,
    position: "sticky",
    margin: 10,
    textAlign: "center",
  },
});

const DeleteSeries = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [imageUrl, setImageUrl] = useState([]);
  const [seriesDeleteOpen, setSeriesDeleteOpen] = useState(false);
  const [seriesId, setSeriesId] = useState("");

  const handleClickOpenSeriesDelete = (seriesId) => {
    setSeriesDeleteOpen(true);
    // console.log(seriesId);
    setSeriesId(seriesId);

    // getSeries(props.id);
  };
  const handleCloseSeriesDelete = () => {
    setSeriesDeleteOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
    getSeries(props.id);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const getSeries = async (studyId) => {
    const session_url = `${process.env.REACT_APP_BACKEND_PHP_URL}/studyid.php`;
    var config = {
      method: "post",
      url: session_url,
      data: {
        sid: props.token,
        studyid: studyId,
      },
    };
    axios.defaults.withCredentials = true;
    const res = await axios(config);
    // console.log(res.data);

    var url = Object.keys(res.data).map(function (key) {
      var url = res.data[key];
      return url;
    });
    setImageUrl(url);
    // console.log(url);
    // setIsLoadingRow(false);
  };
  const seriesDelete = async (seriesId) => {
    const session_url = `${process.env.REACT_APP_BACKEND_PHP_URL}/seriesdelete.php`;
    var config = {
      method: "post",
      url: session_url,
      data: {
        sid: props.token,
        id: seriesId,
      },
    };
    axios.defaults.withCredentials = true;
    const res = await axios(config);
    // setAlertVisibility(true);

    if (!!res.data) {
      setOpen(false);
      setSeriesDeleteOpen(false);
      props.func();
      props.func1();
    }
    // console.log(seriesId);
  };

  return (
    <>
      <Dialog open={seriesDeleteOpen} onClose={handleCloseSeriesDelete}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Typography>
            This will permanently delete the Series with id {seriesId}.
          </Typography>
          <DialogActions>
            <Button onClick={handleCloseSeriesDelete}>Cancel</Button>
            <Button
              onClick={() => seriesDelete(seriesId)}
              style={{ color: "white", backgroundColor: "red" }}
            >
              Delete
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Button
        endIcon={<DeleteIcon />}
        sx={{
          background: "#80848c",
          fontSize: "11px",
          ":hover": {
            bgcolor: "#CE4A3F",
            color: "black",
          },
        }}
        variant="contained"
        onClick={handleClickOpen}
      >
        {props.text}
      </Button>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Click the series to be deleted</DialogTitle>
        <Divider />

        <DialogContent>
          <Typography>This will permanently delete the Series.</Typography>
          <Box>
            <Stack direction="row">
              {imageUrl.map((url, i) => (
                <Box
                  key={i}
                  sx={{
                    position: "relative",
                    paddingTop: 3,
                    cursor: "pointer",
                    paddingRight: 3,
                    "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      width: 160,
                      height: 160,
                      backgroundColor: "black",
                      position: "relative",
                    }}
                  >
                    <img
                      src={url.thumb}
                      alt={url.description}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                    <IconButton
                      onClick={() => handleClickOpenSeriesDelete(url.seriesID)}
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        backgroundColor: "rgba(255, 0, 0, 0.6)",
                        color: "white",
                        borderRadius: "4px",
                        padding: "5px",
                      }}
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                  <Typography gutterBottom variant="body2" textAlign="center">
                    {url.description}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Box>

          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default DeleteSeries;
