import React, { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";
import BackdropIcon from "../BackdropIcon";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

const UploadReport = (props) => {
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [openError, setOpenError] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [uploading, setUploading] = useState(false);
    const [fileName, setFileName] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("info");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFile(file);
        if (file) {
            setFileName(file.name);
        }
    };

    const handleUpload = async () => {
        if (!file) {
            setErrorText("Please select a PDF/Image file to upload.");
            setAlertSeverity("info");
            setOpenError(true);
            return;
        }

        const formData = new FormData();
        formData.append("file", file);
        formData.append("sid", props.token);
        formData.append("seriesid", props.id);

        setUploading(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_PHP_URL}/uploadReport.php`,
                formData,

                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            console.log(response.data);

            if (response.data.Status === "Success") {
                setErrorText("File uploaded successfully!");
                setAlertSeverity("success");
                props.func1();
                props.func();
            } else {
                setErrorText("Failed to upload file. Please try again.");
                setAlertSeverity("error");
            }
        } catch (error) {
            setErrorText("An error occurred during file upload.");
            setAlertSeverity("error");
        }

        setOpenError(true);
        setUploading(false);
    };

    if (uploading) {
        return <BackdropIcon content={<CloudUploadOutlinedIcon />} />;
    } else
        return (
            <>
                <Button
                    sx={{
                        background: "#80848c",
                        fontSize: "11px",
                        ":hover": {
                            bgcolor: "#0290A2",
                            color: "black",
                        },
                    }}
                    onClick={handleClickOpen}
                    variant="contained"
                    endIcon={<CloudUploadIcon />}
                >
                    Attach File
                </Button>
                <Dialog fullWidth open={open} onClose={handleClose}>
                    <DialogTitle>Select an Image or PDF File for Upload</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Box
                            component="form"
                            sx={{
                                "& .MuiTextField-root": { m: 1, width: "45ch" },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Button
                                color="primary"
                                component="label"
                                sx={{
                                    background: "#80848c",
                                    color: "black", // Set text color
                                    padding: "8px 16px",
                                    borderRadius: "4px",
                                    ":hover": {
                                        bgcolor: "#0290A2",
                                        color: "black",
                                    },
                                }}
                            >
                                Select File
                                <UploadFileOutlinedIcon size="large" />
                                <input
                                    type="file"
                                    accept="application/pdf,image/*"
                                    onChange={handleFileChange}
                                    hidden
                                />
                            </Button>
                            <br />
                            {fileName && <p>{fileName}</p>}
                        </Box>
                        <Grid style={{ height: "100%" }} item xs={12}>
                            <Collapse in={openError}>
                                <Fade
                                    in={openError}
                                    timeout={{ enter: 1000, exit: 1000 }}
                                    addEndListener={() => {
                                        setTimeout(() => {
                                            setOpenError(false);
                                            setOpen(false);
                                        }, 2000);
                                    }}
                                >
                                    <Alert
                                        sx={{ mb: 2 }}
                                        variant="filled"
                                        severity={alertSeverity}
                                    >
                                        {errorText}
                                    </Alert>
                                </Fade>
                            </Collapse>
                        </Grid>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button type="submit" onClick={handleUpload} autoFocus>
                                Upload
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </>
        );
};

export default UploadReport;
