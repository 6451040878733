import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import UploadIcon from "@mui/icons-material/Upload";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Checkbox from '@mui/material/Checkbox';
import {
    Stack,
    Card,
    CardMedia,
    CardActionArea,
} from "@mui/material";

const useStyles = makeStyles({
    root: {
        width: 300,
        maxHeight: 360,
        position: "relative",
        margin: 6,
        maxWidth: "10em",
    },
    icon: {
        position: "absolute",
        zIndex: 3,
        bottom: -5,
        right: -5,
        borderRadius: 7,
        backgroundColor: "rgb(107, 107, 107)",
    },
    thumbCover: {
        zIndex: 1,
    },
    thumbDesc: {
        zIndex: 3,
        bottom: 16,
        position: "sticky",
        margin: 10,
        textAlign: "center",
    },
});

const DicomSend = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [servers, setServers] = useState([]);
    const [imageUrl, setImageUrl] = useState([]);
    const [selectedServer, setSelectedServer] = useState("");
    const [openError, setOpenError] = useState(false);
    const [errorText, setErrorText] = useState("Uploading Study, Check Tasks Tab");
    const [selectedSeriesIDs, setSelectedSeriesIDs] = useState([]);

    const handleClickOpen = async () => {
        await getServers();
        await getSeries(props.id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getServers = async () => {
        const session_url = `${process.env.REACT_APP_BACKEND_PHP_URL}/getmodalities.php`;
        var config = {
            method: "post",
            url: session_url,
            data: {
                sid: props.token,
            },
        };
        axios.defaults.withCredentials = true;
        const res = await axios(config);
        const filteredData = Object.fromEntries(
            Object.entries(res.data).filter(([key, value]) => value.Host !== "127.0.0.1")
        );

        setServers(filteredData);
    };

    const getSeries = async (studyId) => {
        const session_url = `${process.env.REACT_APP_BACKEND_PHP_URL}/studyid.php`;
        var config = {
            method: "post",
            url: session_url,
            data: {
                sid: props.token,
                studyid: studyId,
            },
        };
        axios.defaults.withCredentials = true;
        const res = await axios(config);
        var url = Object.keys(res.data).map(function (key) {
            var url = res.data[key];
            return url;
        });
        setImageUrl(url);
        setSelectedSeriesIDs(url.map(url => url.seriesID));
    };

    const handleCheckboxChange = (event, seriesID) => {
        let updatedSelectedSeriesIDs;
        if (event.target.checked) {
            updatedSelectedSeriesIDs = [...selectedSeriesIDs, seriesID];
        } else {
            updatedSelectedSeriesIDs = selectedSeriesIDs.filter(id => id !== seriesID);
        }
        setSelectedSeriesIDs(updatedSelectedSeriesIDs);
        console.log('Updated selected series IDs:', updatedSelectedSeriesIDs);
    };
    const sendDicom = async () => {

        const session_url = `${process.env.REACT_APP_BACKEND_PHP_URL}/dicom_send.php`;
        var config = {
            method: "post",
            url: session_url,
            data: {
                sid: props.token,
                server: selectedServer,
                series: selectedSeriesIDs
            },
        };
        axios.defaults.withCredentials = true;
        const res = await axios(config);
        console.log(res.status);
        setOpenError(true);
        setOpen(false);

        if (res.status != 200) {
            setErrorText("Failed, Contact Support")
            //     props.func();
        }
    };

    const serverKeys = Object.keys(servers);

    return (
        <>
            <Button
                sx={{
                    background: "#80848c",
                    fontSize: "11px",
                    ":hover": {
                        bgcolor: "#0290A2",
                        color: "black",
                    },
                }}
                onClick={handleClickOpen}
                variant="contained"
                endIcon={<UploadIcon />}
            >
                {props.text}
            </Button>
            <Dialog fullWidth open={open} onClose={handleClose}>
                <DialogTitle>Select Server</DialogTitle>
                <Divider />
                <DialogContent>
                    <Box
                        component="form"
                        sx={{
                            "& .MuiTextField-root": { m: 1, width: "45ch" },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            name="selectedModality"
                            margin="dense"
                            id="modalityType"
                            label="Select Server"
                            type="text"
                            fullWidth
                            select
                            value={selectedServer}
                            onChange={(e) => setSelectedServer(e.target.value)}
                        >
                            {serverKeys.map((serverKey, index) => (
                                <MenuItem key={index} value={serverKey}>
                                    {serverKey}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                    <Box>
                        <Stack direction="row">
                            {imageUrl.map((url, i) => (
                                <div key={i}>
                                    <Card className={classes.root}>
                                        <Checkbox
                                            className={classes.icon}
                                            checked={selectedSeriesIDs.includes(url.seriesID)}
                                            onChange={(event) => handleCheckboxChange(event, url.seriesID)}
                                            sx={{ color: "black" }}
                                            size="medium"
                                        />
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                image={url.thumb}
                                                key={Object.keys(url)}
                                                sx={{
                                                    objectFit: "fill",
                                                }}
                                            />
                                        </CardActionArea>
                                    </Card>
                                    <Typography variant="body2" className={classes.thumbDesc}>
                                        {url.description}
                                    </Typography>
                                </div>
                            ))}
                        </Stack>
                    </Box>
                    <Grid style={{ height: "100%" }} item xs={12}>
                        <Collapse in={openError}>
                            <Fade
                                in={openError}
                                timeout={{ enter: 1000, exit: 1000 }}
                                addEndListener={() => {
                                    setTimeout(() => {
                                        setOpenError(false);
                                        setOpen(false);
                                    }, 2000);
                                }}
                            >
                                <Alert sx={{ mb: 2 }} variant="filled" severity="info">
                                    {errorText}
                                </Alert>
                            </Fade>
                        </Collapse>
                    </Grid>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button
                            type="submit"
                            onClick={() => sendDicom()}
                            autoFocus
                            disabled={!selectedServer || selectedSeriesIDs.length === 0}
                        >
                            Send
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={openError}
                autoHideDuration={6000}
                message={errorText}
                onClose={handleClose}
            />
        </>
    );
};

export default DicomSend;
