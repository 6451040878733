import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
const SchedulerEvent = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {"Details of the Study"}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <Typography>Patient Name: {props.data.pname}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Patient ID: {props.data.pid}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Accession Number: {props.data.anumber}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Patient DOB: {props.data.pdob}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Patient Sex: {props.data.psex}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Patient Species: {props.data.pspecies}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Patient Breed: {props.data.pbreed}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Procedure ID: {props.data.prid}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                Procedure Description: {props.data.pdescr}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default SchedulerEvent;
