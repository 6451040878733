import React, { useEffect, useState } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Stack from "@mui/material/Stack";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import MailButton from "./buttons/MailButton";
import DeleteStudy from "./buttons/DeleteStudy";
import DeleteSeries from "./buttons/DeleteSeries";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
// import modalitiesList from "./modalitiesList";
import BackdropIcon from "./BackdropIcon";
import StudyLink from "./buttons/StudyLink";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Divider } from "@mui/material";
import DicomSend from "./buttons/DicomSend";
import UploadReport from "./buttons/UploadReport";

function Row(props) {
    const { row } = props;

    const [instanceId, setInstanceId] = useState("");
    const [imageUrl, setImageUrl] = useState([]);
    const [isLoadingRow, setIsLoadingRow] = useState(true);
    const [isDownloading, setIsDownloading] = useState(false);

    const handleRedirect = async (studyUID) => {
        window.open(`/pacs/viewer/${studyUID}?s=${props.token}`);
    };

    const handleDownloadDicom = async (studyId) => {
        const session_url =
            `${process.env.REACT_APP_BACKEND_PHP_URL}/seriesdownload.php?id=` +
            studyId +
            `&sid=${props.token}`;

        axios.defaults.withCredentials = true;
        setIsDownloading(true);
        axios({
            url: session_url,
            method: "GET",
            responseType: "blob",
        })
            .then((response) => {
                const href = URL.createObjectURL(response.data);
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", studyId + ".zip");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                setIsDownloading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const handleRowClick = async (id) => {
        setInstanceId(id);
    };

    const handleDownloadDicomDir = async (studyId) => {
        const session_url =
            `${process.env.REACT_APP_BACKEND_PHP_URL}/studydownload.php?id=` +
            studyId +
            `&sid=${props.token}`;
        setIsDownloading(true);
        axios({
            url: session_url,
            method: "GET",
            responseType: "blob",
        }).then((response) => {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", studyId + ".zip");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setIsDownloading(false);
        });
    };

    const getInstance = async (id) => {
        const session_url = `${process.env.REACT_APP_BACKEND_PHP_URL}/studyid.php`;
        var config = {
            method: "post",
            url: session_url,
            data: {
                sid: props.token,
                studyid: id,
            },
        };
        axios.defaults.withCredentials = true;
        const res = await axios(config);
        var url = Object.keys(res.data).map(function (key) {
            var url = res.data[key];
            return url;
        });
        setImageUrl(url);
        setIsLoadingRow(false);
    };

    if (isDownloading) {
        return <BackdropIcon content={<FileDownloadIcon />} />;
    }

    return (
        <React.Fragment>
            <TableRow
                sx={{
                    "& > *": { borderBottom: "none", padding: 10 },
                    "& td": { border: 0 },
                }}
                onClick={() => {
                    handleRowClick(row.ID);
                    getInstance(row.ID);
                    props.onClick(props.open ? "" : row.ID);
                }}
                hover
            >

                <TableCell align="left">
                    <Typography variant="body1">
                        Patient Name:{" "}
                        {row.PatientMainDicomTags.PatientName}
                    </Typography>
                    <Typography variant="body1">
                        {row.MainDicomTags.StudyDate.substring(6, 8) +
                            "-" +
                            row.MainDicomTags.StudyDate.substring(4, 6) +
                            "-" +
                            row.MainDicomTags.StudyDate.substring(0, 4) +
                            ", " +
                            row.MainDicomTags.StudyTime.substring(0, 2) +
                            ":" +
                            row.MainDicomTags.StudyTime.substring(2, 4)}
                    </Typography>
                    <Typography variant="body1">
                        {row.MainDicomTags.StudyDescription}
                    </Typography>
                    <Typography variant="body1">
                        {row.RequestedTags.ModalitiesInStudy}
                    </Typography>
                    <Typography variant="body1">
                        {row.MainDicomTags.StudyDescription}
                    </Typography>
                </TableCell>
                <TableCell align="right">
                    <Stack direction="column" spacing={0.5}>
                        {/* <TableCell align="center"> */}
                        <Button
                            aria-label="view study"
                            sx={{
                                background: "#80848c",
                                ":hover": {
                                    bgcolor: "#0290A2",
                                    color: "black",
                                },
                            }}
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent row click
                                handleRedirect(row.MainDicomTags.StudyInstanceUID);
                            }}
                            endIcon={<VisibilityIcon />}
                            variant="contained"
                        >View

                        </Button>
                        {/* </TableCell> */}
                        <Button
                            sx={{
                                background: "#80848c",
                                ":hover": {
                                    bgcolor: "#0290A2",
                                    color: "black",
                                },
                            }}
                            endIcon={<FileDownloadIcon />}
                            variant="contained"
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent row click
                                handleDownloadDicom(row.ID);
                            }}
                        >
                            Download
                        </Button>
                        <DeleteSeries
                            id={row.ID}
                            func={props.func}
                            token={props.token}
                            text={'delete'}
                            func1={() => getInstance()}
                            sx={{ fontSize: "10px" }}
                        />
                        <MailButton id={row.ID} token={props.token} sx={{ fontSize: "10px" }} />
                        <StudyLink id={row.ID} token={props.token} sx={{ fontSize: "10px" }} text={'link'} />
                        <DicomSend id={row.ID} token={props.token} text={'send'} />
                        <UploadReport id={row.ID} token={props.token} />
                    </Stack>
                </TableCell>

            </TableRow>
            <TableRow>
                <TableCell colSpan={3} sx={{ padding: 0 }}>
                    <Divider />
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function Studies(props) {
    //file_upload
    const [isLoading, setIsLoading] = useState(true);
    const [isMounted, setIsMounted] = useState(true);
    //
    const [patientId, setPatientId] = useState("");
    const [patientName, setPatientName] = useState("");
    const [ownerName, setOwnerName] = useState("");
    const [modality, setModality] = useState(""); //modality not in dicom tags
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    console.log(toDate)
    // const [token, setToken] = useState("");

    const [studyData, setStudyData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [limit, setLimit] = useState(25);
    const [offset, setOffset] = useState(0);
    const [nextDisabled, setNextDisabled] = useState(false);
    const [openRow, setOpenRow] = useState("");

    const handleChangeRows = (event) => {
        setLimit(event.target.value);
    };

    useEffect(() => {
        if (isMounted) {
            getRes(props.token);
        }
    }, [
        rowsPerPage,
        patientId,
        patientName,
        ownerName,
        modality,
        fromDate,
        toDate,
        DeleteStudy,
        page,
        limit,
        offset,
        props.trigger,
    ]);

    useEffect(() => {
        return () => {
            setStudyData(null);
            setIsMounted(false);
        };
    }, []);

    const previousPage = () => {
        setOffset(offset - limit);
    };
    const nextPage = () => {
        setOffset(offset + limit);
    };

    const getRes = async (token) => {
        if (!!token) {
            const session_url = `${process.env.REACT_APP_BACKEND_PHP_URL}/studylist.php`;
            var config = {
                method: "post",
                url: session_url,
                data: {
                    sid: token,
                    pn: patientName,
                    pi: patientId,
                    on: ownerName,
                    mn: modality,
                    fd: fromDate,
                    td: toDate,
                    pages: limit,
                    offset: offset,
                },
            };
            axios.defaults.withCredentials = true;
            const res = await axios(config);
            setStudyData(res.data);
            setIsLoading(false);
            setNextDisabled(res.data.length < limit);
            if ((res.data.length === 0) & (offset !== 0)) {
                setOffset(offset - limit);
            }
        }
    };

    if (isLoading) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <div>
            <Box
                component="form"
                sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginBottom: 2,
                }}
                noValidate
                autoComplete="off"
            >
                <TextField
                    id="standard-basic"
                    label="Patient Name "
                    variant="standard"
                    onChange={(e) => setPatientName(e.target.value)}
                />
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            inputFormat="DD-MM-YYYY"
            label="Date"
            value={fromDate}
            onChange={(newFromDate) => {
                const nextDay = new Date(newFromDate);
                nextDay.setDate(nextDay.getDate() + 1);
                setFromDate(newFromDate);
                setToDate(nextDay);
            }}
            renderInput={(params) => (
              <TextField
                variant="standard"
                {...params}
                error={false}
                sx={{ svg: { color: "#B5B9C1" }, }}
              />
            )}
          />
        </LocalizationProvider> */}
            </Box>
            <TableContainer component={Paper} >
                <Table aria-label="Study Table">
                    <TableBody>
                        {studyData.length > 0 &&
                            studyData?.map((row) => (
                                <><Row
                                    key={row.ID}
                                    row={row}
                                    func={() => getRes(props.token)}
                                    token={props.token}
                                    open={row.ID == openRow}
                                    onClick={(name) => setOpenRow(name)} /></>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Select
                    value={limit}
                    onChange={handleChangeRows}
                    variant="standard"
                    disableUnderline
                    sx={{
                        ".MuiSvgIcon-root ": {
                            fill: "white !important",
                        },
                    }}
                >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                </Select>
                <IconButton
                    onClick={previousPage}
                    disabled={offset <= 0}
                    sx={{
                        "&.MuiIconButton-root": {
                            color: "#B5B9C1",
                        },
                        "&.Mui-disabled": {
                            color: "#0000008a",
                        },
                    }}
                >
                    <KeyboardArrowLeftIcon />
                </IconButton>
                <IconButton
                    sx={{
                        "&.MuiIconButton-root": {
                            color: "#B5B9C1",
                        },
                        "&.Mui-disabled": {
                            color: "#0000008a",
                        },
                    }}
                    onClick={nextPage}
                    disabled={nextDisabled}
                >
                    <KeyboardArrowRightIcon />
                </IconButton>
            </div>
        </div>
    );
}
